<style lang="less" scoped>
</style>

<template>
  <router-view />
</template>

<script>

export default {
  data () {
    return {
    }
  },
  created () {
    if (this.$Cookies.get('cecc-lang') === 'en') this.$router.push({
      name: 'serviceDirectoryEn'
    })
  }
}
</script>
