import { render, staticRenderFns } from "./service-directory.vue?vue&type=template&id=85b1c07c&scoped=true&"
import script from "./service-directory.vue?vue&type=script&lang=js&"
export * from "./service-directory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85b1c07c",
  null
  
)

export default component.exports